const mainURL = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;

const URLs = {
  mainURL: mainURL,
  config: mainURL + "/api/config",
  login: mainURL + "/api/user/login",
  user: mainURL + "/api/user",
  department: mainURL + "/api/user/department",
  me: mainURL + "/api/user/me",
  member: mainURL + "/api/member",
  pet: mainURL + "/api/pet",
  stock: mainURL + "/api/stock-category",
  stockMaster: mainURL + "/api/stock-master",
  sales: mainURL + "/api/sales",
  product: mainURL + "/api/stock-master",
  pos: mainURL + "/api/pos",
  medical: mainURL + "/api/medical",
  pharmacy: mainURL + "/api/medical/pharmacy",
  tempSales: mainURL + "/api/temp-sales",
  config: mainURL + "/api/config",
  reservation: mainURL + "/api/reservation",
  data: mainURL + "/api/data",
  leave: mainURL + "/api/leave",
  overtime: mainURL + "/api/overtime",
  approve: mainURL + "/api/approve",
  manipulateType: mainURL + "/api/manipulate-type",
  hospitalize: mainURL + "/api/hospitalize",
  salePoint: mainURL + "/api/pos",
};

export default URLs;
